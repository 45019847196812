import { Box, Grid, Typography } from '@material-ui/core'
import {
    FileImageOutline,
    FilePdfOutline,
    FileWordOutline,
} from 'mdi-material-ui'

export interface Document {
    label: string
    type: 'word' | 'pdf' | 'image'
}

export interface DocumentsProps {
    documents: Document[]
}

const icons = {
    word: <FileWordOutline />,
    pdf: <FilePdfOutline />,
    image: <FileImageOutline />,
}

export const Documents: React.FC<DocumentsProps> = ({ documents }) => {
    return (
        <Grid container spacing={1}>
            {documents.map((document, idx) => (
                <Grid item xs={4} key={idx}>
                    <Box
                        alignItems='center'
                        display='flex'
                        flexDirection='column'
                    >
                        <Box mb={0.5}>{icons[document.type]}</Box>
                        <Typography align='center' variant='caption'>
                            {document.label}
                        </Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
    )
}
