import { Box } from '@material-ui/core'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface WrapperProps {}

export const Wrapper: React.FC<WrapperProps> = ({ children }) => {
    return (
        <Box display='flex' flexDirection='column' height='100%' p={4}>
            {children}
        </Box>
    )
}
