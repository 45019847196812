import { Grid } from '@material-ui/core'
import { Card } from 'components/molecules/Card'
import { Layout, LayoutProps } from 'components/molecules/Layout'
import { summaryConfig } from 'config'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ApplicationNewProps {
    layout: LayoutProps
}

export const ApplicationNew: React.FC<ApplicationNewProps> = ({ layout }) => {
    return (
        <Layout {...{ ...layout, noButtons: true, progress: 35 }}>
            <Grid container spacing={1} style={{ height: '100%' }}>
                {Object.keys(summaryConfig).map((summaryItem) => {
                    const item = summaryConfig[summaryItem]
                    return (
                        <Grid key={item.label} item xs={12} sm={6}>
                            <Card
                                onClick={() =>
                                    layout.send(summaryItem.toUpperCase())
                                }
                                {...item}
                                title={item.label}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Layout>
    )
}
