import { LinearProgress } from '@material-ui/core'
import { Action, ActionNoLabel } from 'components/molecules/ActionButtons'
import { AppBar } from 'components/molecules/AppBar'
import { HeaderProps } from 'components/molecules/Header'
import { Wrapper } from 'components/molecules/Wrapper'
import { useConfirm } from 'material-ui-confirm'
import { ArrowLeft, Close } from 'mdi-material-ui'
import { ReactNode, useState } from 'react'
import { StepCurrency } from '../StepCurrency'
import { StepIntro } from '../StepIntro'
import { StepRadio } from '../StepRadio'

export interface Step {
    actions?: Action[]
    children?: ReactNode
    type: 'intro' | 'radio' | 'text' | 'currency' | 'number'
    header: HeaderProps
    navigation?: boolean
}

export interface StepLast {
    action: ActionNoLabel
    header?: HeaderProps
}
export interface FormProps {
    steps: Step[]
    onComplete: () => void
    onReset?: () => void
    title?: string
}

export const Form: React.FC<FormProps> = ({
    steps,
    onComplete,
    onReset: onResetProp,
    title,
}) => {
    const [activeField, setActiveField] = useState(0)
    const confirm = useConfirm()
    const onPrevious = () =>
        setActiveField((activeFieldOld) => activeFieldOld - 1)
    const onNext = () => setActiveField((activeFieldOld) => activeFieldOld + 1)
    const onReset =
        onResetProp ||
        (() => {
            setActiveField(0)
        })
    const progress = (activeField / steps.length) * 100

    const stepsWithMockActions = (steps[activeField]?.actions || []).map(
        (action) => ({
            ...action,
            onClick: onNext,
        })
    )

    const questionHeader = steps[activeField]?.header

    const appBarActions = []

    if (activeField)
        appBarActions.push({
            icon: <ArrowLeft />,
            onClick: onPrevious,
        })

    appBarActions.push({
        icon: <Close />,
        onClick: () => {
            confirm({
                description: 'Are you sure you want to lose all progress?',
                title: 'Please Confirm',
            }).then(() => {
                onReset()
            })
        },
    })

    if (!steps[activeField]) {
        onComplete?.()
        return null
    }

    return (
        <Wrapper>
            <AppBar
                actions={
                    steps[activeField]?.navigation === false
                        ? []
                        : appBarActions
                }
                title={title}
            />
            <LinearProgress
                value={progress}
                style={{
                    top: 0,
                    height: 6,
                    left: 0,
                    position: 'absolute',
                    width: '100%',
                }}
                variant='determinate'
            />
            <>
                {steps[activeField].type === 'intro' && (
                    <StepIntro
                        children={steps[activeField].children}
                        header={questionHeader}
                        key={activeField}
                        onNext={onNext}
                    />
                )}
                {steps[activeField].type === 'radio' && (
                    <StepRadio
                        actions={stepsWithMockActions}
                        header={questionHeader}
                        key={activeField}
                    />
                )}
                {steps[activeField].type === 'currency' && (
                    <StepCurrency
                        header={questionHeader}
                        key={activeField}
                        onNext={onNext}
                    />
                )}
            </>
        </Wrapper>
    )
}
