import { Button, Dialog, Slide, Typography } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import { AccountManager } from 'components/pages/AccountManager'
import { Application } from 'components/pages/Application'
import { CreditJourneyStart } from 'components/pages/CreditJourneyStart'
import { Welcome } from 'components/pages/Welcome/Welcome'
import { ApplicationNew } from 'components/templates/ApplicationNew'
import { journeys, questions } from 'config'
import { useFlags } from 'config/flags'
import useStateMachine from 'config/useStateMachine'
import React from 'react'
import { Form } from '../Form'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RouterProps {}

export const Router: React.FC<RouterProps> = () => {
    const [state, send] = useStateMachine()
    const [flags] = useFlags()
    // console.log(state.value)
    console.log(state.value)
    console.log(flags)

    if (state.matches('new.intro'))
        return <Welcome layout={{ chat: false, send }} />

    if (state.matches('buyer.intro'))
        return (
            <Form
                onComplete={() => send('NEXT')}
                onReset={() => send('RESET')}
                steps={journeys.buyer}
            />
        )
    if (state.matches('renter.intro'))
        return (
            <Form
                onComplete={() => send('RESET')}
                onReset={() => send('RESET')}
                steps={journeys.renter}
            />
        )
    if (state.matches('seller.intro'))
        return (
            <Form
                onComplete={() => send('RESET')}
                onReset={() => send('RESET')}
                steps={journeys.seller}
            />
        )
    if (state.matches('developer.intro'))
        return (
            <Form
                onComplete={() => send('RESET')}
                onReset={() => send('RESET')}
                steps={journeys.developer}
            />
        )
    if (state.matches('accountManager.awaiting'))
        return <AccountManager layout={{ chat: false, send }} />
    if (state.matches('accountManager.assigned'))
        return <AccountManager name='Carla' layout={{ send }} />
    if (state.matches('creditJourney.start'))
        return <CreditJourneyStart layout={{ noButtons: true, send }} />
    if (
        state.matches('creditJourney.summary') ||
        state.matches('creditJourney.property.view') ||
        state.matches('creditJourney.mortgage') ||
        state.matches('creditJourney.you') ||
        state.matches('creditJourney.documents')
    )
        return flags.tiles ? (
            <ApplicationNew layout={{ send }} />
        ) : (
            <Application layout={{ send, state }} />
        )

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & { children?: React.ReactElement<any, any> },
        ref: React.Ref<unknown>
    ) {
        return <Slide direction='up' ref={ref} {...props} />
    })

    if (state.matches('creditJourney.property.edit'))
        return (
            <>
                <Form
                    onComplete={() => send('SUMMARY')}
                    onReset={() => send('SUMMARY')}
                    steps={questions.mortgage}
                />
                {/* <Button onClick={() => send('CANCEL')}>view</Button> */}
            </>
        )

    return (
        <Dialog
            fullScreen
            open={false}
            // open={
            //     state.matches('creditJourney.property') ||
            //     state.matches('creditJourney.mortgage') ||
            //     state.matches('creditJourney.you') ||
            //     state.matches('creditJourney.documents')
            // }
            TransitionComponent={Transition}
        >
            {state.matches('creditJourney.property.view') && (
                <>
                    <Typography>Hello</Typography>
                    <Button onClick={() => send('EDIT')}>edit</Button>
                    <Button onClick={() => send('SUMMARY')}>cancel</Button>
                </>
            )}
            {state.matches('creditJourney.property.edit') && (
                <>
                    <Form
                        onComplete={() => send('SUMMARY')}
                        onReset={() => send('SUMMARY')}
                        steps={questions.mortgage}
                    />
                    {/* <Button onClick={() => send('CANCEL')}>view</Button> */}
                </>
            )}
        </Dialog>
    )
}
