import { Box } from '@material-ui/core'
import { Layout, LayoutProps } from 'components/molecules/Layout'
import { Category } from 'components/organisms/Category'
import { config } from 'config'
import flatten from 'flat'
import { useState } from 'react'
import { BottomNavigation } from './BottomNavigation'
import { ApplicationStepper } from './Stepper'
import { Summary } from './Summary'

export interface ApplicationProps {
    layout: LayoutProps
}

export const Application: React.FC<ApplicationProps> = ({ layout }) => {
    const [activeStep] = useState(0)
    const setTab = (category: string) => {
        layout.send(`${category.toUpperCase()}`)
    }
    const state2 = Object.keys(flatten(layout.state?.value))[0]
    const tab = state2 === 'creditJourney' ? 'summary' : state2.split('.')[1]
    const categories = Object.keys(config).filter((item) => item !== 'summary')

    return (
        <Layout {...{ ...layout, noButtons: true, progress: 35 }}>
            <ApplicationStepper activeStep={activeStep} tab={tab} />
            {layout.state?.matches('creditJourney.summary') && <Summary />}
            {categories.map((category) => (
                <Box key={category}>
                    {layout.state?.matches(
                        `creditJourney.${category}.view`
                    ) && (
                        <Category
                            fields={config[category].fields || []}
                            header={{ heading: config[category].label }}
                            layout={layout}
                        />
                    )}
                    {/* {layout.state?.matches(
                        `creditJourney.${category}.edit`
                    ) && (
                        <>
                            <Form
                                onComplete={() => layout.send('SUMMARY')}
                                onReset={() => layout.send('SUMMARY')}
                                steps={questions.mortgage}
                            />
                            <Button onClick={() => layout.send('CANCEL')}>
                                view
                            </Button>
                        </>
                    )} */}
                </Box>
            ))}
            <BottomNavigation tab={tab} setTab={setTab} />
        </Layout>
    )
}
