import { Box, Button } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { LayoutProps } from 'components/molecules/Layout'
import { TextWithImage } from '../TextWithImage'
import { BePatientIllustration } from './BePatientIllustration'
import { DigitalCustomerServiceIllustration } from './DigitalCustomerServiceIllustration'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AccountManagerProps {
    layout: LayoutProps
    name?: string
}

export const AccountManager: React.FC<AccountManagerProps> = ({
    name,
    layout,
}) => {
    return (
        <TextWithImage
            header={{
                heading: name
                    ? `Meet your Account Manager: ${name}`
                    : 'Assigning an Account Manager',
                subheading: name
                    ? 'We have assigned a representative to assist you with your journey. From now on, anytime you need to talk - use the top right icon'
                    : 'This is all we need from you at the moment. Sit back and relax while we assign one of our agents to assist you',
            }}
            layout={{ ...layout, noButtons: true }}
        >
            {/* {name && (
                <Typography align='center' color='primary' variant='h1'>
                    {name}
                </Typography>
            )} */}
            <Box maxHeight='35vh' width='100%'>
                {name ? (
                    <DigitalCustomerServiceIllustration />
                ) : (
                    <BePatientIllustration />
                )}
            </Box>
            <Box flex={1} />
            {name && (
                <Box>
                    <Button
                        color='primary'
                        fullWidth
                        endIcon={<ArrowForward />}
                        onClick={() => layout.send('START')}
                        size='large'
                        style={{ color: 'white' }}
                        variant='contained'
                    >
                        Start Here
                    </Button>
                </Box>
            )}
        </TextWithImage>
    )
}
