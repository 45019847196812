import { useTheme } from '@material-ui/core'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BePatientIllustrationProps {
    height?: string
    primary?: string
    secondary?: string
}

export const BePatientIllustration: React.FC<BePatientIllustrationProps> = ({
    height = '100%',
    primary,
    secondary,
}) => {
    const theme = useTheme()

    const primaryColor = primary || theme.palette.primary.main

    const secondaryColor = secondary || theme.palette.text.primary
    return (
        <svg
            viewBox='0 0 400 400'
            height={height}
            width='100%'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g transform='matrix(2,0,0,2,0,0)'>
                <path
                    d='M87.257,34.72a12.417,12.417,0,0,0-.719,9.318c.193.649,1,.487.873-.24a14.1,14.1,0,0,1,.456-7.44c2.8-7.494,15.683-8.5,20.806-2.217a9.787,9.787,0,0,1,1.962,8.715c-.208.7.773.919,1,.108a10.349,10.349,0,0,0-2.009-9.5C104.2,26.685,91.086,27.248,87.257,34.72Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M106.316,44.841a4.907,4.907,0,0,0-5.091-.572.626.626,0,0,0,.347,1.151,6.338,6.338,0,0,1,3.809.379C106.549,46.324,106.73,45.168,106.316,44.841Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M95.631,44.516c-1.126-.467-3.863.4-4.728,1.136-.649.533-.394,1.259.541.966a7.745,7.745,0,0,1,3.461-.665C96.52,46.077,96.351,44.815,95.631,44.516Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M100.143,56.43a2.462,2.462,0,0,0,2.774-1.059c.4-.819-.417-1.128-.842-.772a2.458,2.458,0,0,1-1.924.865C99.425,55.5,99.356,56.2,100.143,56.43Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M98.351,48.99c-.525.163-.773,1.5-.873,2-.155,1.136-.51,2.41.981,2.65a3.749,3.749,0,0,0,1.785-.317c.626-.355.409-.919-.278-.881-.495,0-1.152.224-1.368-.062C98.258,51.933,99.34,48.682,98.351,48.99Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M104.539,49.3c.4-.611,0-.958-.6-.572a2.019,2.019,0,0,1-1.7.147c-.7-.17-.982.34-.333.827A1.908,1.908,0,0,0,104.539,49.3Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M94.557,49.175a1.567,1.567,0,0,1-1.638,0c-.772-.347-1.066.279-.463.773a2.117,2.117,0,0,0,2.665-.039C95.8,49.237,95.389,48.679,94.557,49.175Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M109.716,45.66c-.342.446.116.973.571.6,2.65-2.2,2.913.332,2.187,2.5-1.229,3.623-2.882,1.622-3.593,3.647a20.083,20.083,0,0,1-3.26,6.134c-3.183,3.917-7.378,4.118-10.646,2.016s-3.724-4.326-5.092-7.486c-.417-.966-3.044,1.468-4.249-3.26-.772-2.921.981-3.8,1.885-2.952.363.34.935.124.773-.37-.773-2.2-5.571-1.067-3.67,4.357.688,1.978,2.434,3.616,4.473,3.09A13.137,13.137,0,0,0,94.3,61.66a8.44,8.44,0,0,0,10.684-.6c.221,4.181.053,5.181,1.5,5.245a10.39,10.39,0,0,0,1.692,0c.5-.077,1.136-.865-.278-1.2-.309-.07-1.313-.054-1.329-.17-.224-1.808-.247-3.423-.371-5.138a21.428,21.428,0,0,0,3.817-7.193c2.271-.394,3.353-2.665,3.747-4.11C114.9,44.3,111.693,43.082,109.716,45.66Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M81.192,101.263a39.062,39.062,0,0,1,1.707,9.07c.062.618.958.525,1.043-.039.806-5.331-2.454-10.748-3.307-18.689-.131-1.258-2.626-2.463.132,8.066-3.284,4.087-6.907,11.18-14.911,12.6-11.589,2.055-28.539-12.307-43.875-16.417-1.151-.309-1.461-.255-2.225.378-1.847,1.545-3.454,2.628-7.726,3.091-.68.077-.688.812,0,.88a11.48,11.48,0,0,0,8.939-3.268c13.906,3.786,26.538,13.514,37.6,16.225C69.217,115.718,75.784,109.53,81.192,101.263Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M93.993,62.363c-.873-.012-.556,2.913-.432,3.863-6.814.7-12.7,1.545-17.561,6.8C68.776,80.844,68.475,90.1,60.247,99.254c-.569-.6-19.733-6.709-29.428-8.707a4.345,4.345,0,0,0-3.376-7.664c-.541.062-.41.966.1,1.043,4.636.7,3.863,4.636,2.179,5.787-1.028.711-.773,1.506,0,1.707,6.657,1.864,14.395,3.477,29.783,8.831.9.309,1.19.3,1.854-.363C70.244,91,70.46,75.165,82.884,69.757c3.453-1.545,7.293-1.962,11.009-2.387.309,0,.912-.4.773-1.376C94.449,63.762,94.82,62.375,93.993,62.363Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M19.423,94.619a.634.634,0,0,0-.579-1.105c-4.226,1.414-5.007,1.877-9.673,1.221a.526.526,0,0,0-.3.989C11.883,96.937,17.128,96.4,19.423,94.619Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M19.285,92.193c.831-.256.618-1.545-.286-1.36-4.335.858-6.791-.873-8.792-2.719-.364-.333-1.337,0-.572.942A8.951,8.951,0,0,0,19.285,92.193Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M20.775,90.03c.959.554,2.14,0,.773-1.144a3.094,3.094,0,0,1,1.367-5.516.494.494,0,1,0-.177-.973C18.4,82.953,17.68,88.24,20.775,90.03Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M87.6,93.437c.871,1.222,3.09.664,2.742-.587C90.022,91.838,86.7,92.179,87.6,93.437Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M190.041,91.312a21.814,21.814,0,0,1-9.579,1.87,3.077,3.077,0,0,0-2.4.773C168.023,99.849,154.7,110.8,143.3,111.616c-5.408.386-8.282-1.545-11.759-5.3-.417-.463-.849.094-.634.542,2.141,4.5,7.726,6.474,12.362,6.134,11.836-.85,25.657-12.1,36.18-18.4,3.331.278,8.808-.772,11.064-2.318A.541.541,0,0,0,190.041,91.312Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M180.222,88.438a11.421,11.421,0,0,0,8.731-9.665c.076-.61-.927-.881-1.09-.255-1.407,5.555-4.01,6.729-8.4,8.862C179.04,87.619,178.705,88.741,180.222,88.438Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M174.93,81.191c1.174-.224,3.7-.927,4.017,1.175a4,4,0,0,1-1.97,3.863c-.772.479-.077,1.491,1.2.71a5.352,5.352,0,0,0,2.133-4.836c-.5-2.719-3.308-2.82-5.663-1.908A.533.533,0,0,0,174.93,81.191Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M142.891,97.052c.673,1.29.563,1.113,1.939.982,7.833-.773,18.711-4.52,25.936-8.105.587-.293,1.321-.9.463-1.545-2.966-2.086-1.931-5.493,1.02-6.343.571-.162.532-1.12-.116-1.082a4.609,4.609,0,0,0-2.426,8.159,93.469,93.469,0,0,1-25.349,7.664c-3.569-6.791-11.4-23.178-18.209-26.809-.488-.255-.981.371-.426.866C133.611,77.776,137.374,86.48,142.891,97.052Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M190.405,85.116c-5.122,5.61-8.708,4.358-10.221,4.844-.356.116-.966,1.128.533,1.314,3.639.44,8.868-2.395,10.623-5.548C191.672,85.155,190.822,84.66,190.405,85.116Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M87.589,154.757c-4.333,1.418-7.726,4.7-12.532,6.142-4.164,1.236-18.086-.773-16.386-4.636,1.993-4.6,13.6-2.874,21.153-8.2.487-.348,0-1.345-.772-.958-4.636,2.264-6.034,2.449-12.54,3.731-3.221.641-7.54,1.391-9.271,4.636-3.152,5.887,11.589,9.124,18.774,6.822,4.188-1.345,7.8-4.636,11.975-6.458C89,155.353,88.671,154.4,87.589,154.757Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M139.985,149.079a30.555,30.555,0,0,0-9.541-4.821c-.989-.348-1.29.687-.379,1a32.68,32.68,0,0,1,9.38,5.239c1.839,1.583,2.812,2.866.07,4.4-5.208,2.936-11.18,4-16.109-.092-2.209-1.84-4.087-4.127-6.7-5.409a.581.581,0,0,0-.571,1.013c2.371,1.6,4.241,3.908,6.551,5.739,5.455,4.32,13.134,3.6,18.659-.286C144.613,153.521,142.376,151.024,139.985,149.079Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M60.718,9.294a3.391,3.391,0,0,1,3.863-.123c.842.463,1.159.587,1.932-.132a3.5,3.5,0,0,1,3.863-1.073c.455.108.533-.449.193-.642a4.1,4.1,0,0,0-4.976,1.028c-.772-.294-2.673-1.839-5.408.363C59.776,9.055,60.238,9.643,60.718,9.294Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M46.912,20.891c1.26-3.254,5.1-3.091,6.59-.278a.719.719,0,0,0,1.344-.248c.418-2.426,3.23-4.511,6.421-2.2a.518.518,0,0,0,.687-.773,4.978,4.978,0,0,0-8,1.259,4.748,4.748,0,0,0-8,1.878.512.512,0,0,0,.958.363Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M94.326,7.772C96.4,5.4,98.567,6.451,99.857,6.9c.619.209.943-.425.263-.927a4.814,4.814,0,0,0-6.737.935C92.889,7.494,93.89,8.271,94.326,7.772Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M85.5,9.449c1.073-2.741,4.435-1.669,6.25-.271,1.035.773,2.156-.2.7-1.213C89.9,6.181,85.789,5.57,84.622,9.132A.464.464,0,0,0,85.5,9.449Z'
                    fill={secondaryColor}
                ></path>
                <path
                    d='M105.505,183.868c-19.469.88-43.474,2.634-43.474,6.444s14.2,4.535,38.205,4.681,50.063-2.047,49.183-6S123.491,183.055,105.505,183.868Z'
                    fill={primaryColor}
                ></path>
                <path
                    d='M134.269,114.405c-16.541,2.785-43.914,21.084-54.452,31.474A19.092,19.092,0,0,1,90.8,155.243c3.09-1.753,23.857-8.05,45.815-14.486s20.643-15.814,20.087-18.595S150.81,111.62,134.269,114.405Z'
                    fill={primaryColor}
                ></path>
                <path
                    d='M104.114,125.577a108.48,108.48,0,0,0-26.839-9.271c-8.1-1.654-20.69-1.948-25.5,4.635s2.635,14.888,8.785,17.422,14.447,3.615,19.229,5.076C83.108,139.429,95.037,129.555,104.114,125.577Z'
                    fill={primaryColor}
                ></path>
                <path
                    d='M129.656,113.084c1.785-13.752-4.3-40.237-5.246-44.3-.826-3.562-2.874-4.682-7.725-5.169-3.987-.4-6.1,0-6.459,2.774S99.17,99.4,80.867,115.309c7.541,1.042,19.909,5.315,25.256,8.683C111.323,120.23,123.46,115.069,129.656,113.084ZM118.315,76.85c0-.649.7-.773.772-.094a65.733,65.733,0,0,1,0,15.344c-.456,4.1-2.032,11.273-3.7,14.949-.263.573-.866.363-.773-.269C115.572,101.154,118.367,95.834,118.315,76.85ZM101.6,110.178c1.3-2.7,2.665-5.338,3.863-8.073a102.208,102.208,0,0,0,8.42-30.463c.071-.641.773-.61.72,0-1.148,13.977-4.344,26.918-12.278,38.953a.44.44,0,1,1-.725-.417Z'
                    fill={primaryColor}
                ></path>
                <path
                    d='M59.374,69.286c2.542,0,2.256-.533,2.109-2.867-.325-5.1-1.854-12.546-6.837-14.37-7.332-2.681-16.712,3.091-20.482,9.488a9.612,9.612,0,0,0-16,4.89c-.131.61.9.873,1.074.271a8.454,8.454,0,0,1,14.517-3.516.772.772,0,0,0,1.251-.177c3.091-6.034,11.087-11.334,18.063-9.913,5.718,1.167,6.869,9.851,7.023,14.633-13.25,0-26.693.417-40.537.278-.657,0-.773,1.028-.07,1.051C32.735,69.494,44.88,69.27,59.374,69.286Z'
                    fill={primaryColor}
                ></path>
                <path
                    d='M176.923,35.315c-1.475-14.911-23.108-16.757-26.315-1.259-8.243-6.467-19.971,1.846-16.95,11.4.549,1.738.642,2.147,2.357,2.194,13.613.355,36.141-.348,51.06-1.607.633-.054.626-1.105,0-1.066-17.043.849-34.766,1.661-51.663,1.081-2.82-10.638,8.135-16.6,15.227-10.043a.774.774,0,0,0,1.237-.572c.7-13.907,23.479-14.757,23.8,1.058a.672.672,0,0,0,1.035.541,6.736,6.736,0,0,1,11.009,5.184.6.6,0,0,0,1.2,0A8.016,8.016,0,0,0,176.923,35.315Z'
                    fill={primaryColor}
                ></path>
            </g>
        </svg>
    )
}
