import { Box, Typography } from '@material-ui/core'
import { Field } from 'config'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FieldsProps {
    fields: Field[]
}

export const Fields: React.FC<FieldsProps> = ({ fields }) => {
    return (
        <>
            {fields.map((field, idx) => (
                <Box
                    alignItems='center'
                    mt={1}
                    mb={1}
                    display='flex'
                    key={idx}
                    width='100%'
                >
                    <Box flex='1'>{field.label}</Box>
                    <Box>
                        <Typography variant='h6'>{field.value}</Typography>
                    </Box>
                </Box>
            ))}
        </>
    )
}
