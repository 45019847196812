import { Box, Button, ButtonProps } from '@material-ui/core'
import { ReactNode } from 'react'

export interface Action {
    buttonProps?: ButtonProps
    label: string
    onClick?: () => void
}
export interface ActionNoLabel extends Omit<Action, 'label' | 'onClick'> {
    label?: string
    onClick: () => void
}
export interface ActionIcon extends Omit<Action, 'label' | 'onClick'> {
    icon?: ReactNode
    onClick: () => void
}

export interface ActionButtonsProps {
    actions: Action[]
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ actions }) => {
    return (
        <Box display='flex' flexDirection='column' style={{ gap: 24 }}>
            {actions.map(({ buttonProps, label, onClick }) => (
                <Button
                    color='primary'
                    key={label}
                    onClick={onClick}
                    size='large'
                    style={{ color: 'white' }}
                    variant='contained'
                    {...buttonProps}
                >
                    {label}
                </Button>
            ))}
        </Box>
    )
}
