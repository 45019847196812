import { Box, Button, Typography, useTheme } from '@material-ui/core'
import { ArrowBack, Clear, LiveHelp } from '@material-ui/icons'
import { useFlags } from 'config/flags'
import { useConfirm } from 'material-ui-confirm'
import { CircleSlice3 } from 'mdi-material-ui'
import { AnyEventObject, State } from 'xstate'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LayoutProps {
    chat?: boolean
    noButtons?: boolean
    noReset?: boolean
    progress?: number
    send: any
    state?: State<
        unknown,
        AnyEventObject,
        any,
        {
            value: any
            context: unknown
        }
    >
}

export const Layout: React.FC<LayoutProps> = ({
    children,
    chat = true,
    noButtons,
    noReset,
    progress,
    send,
}) => {
    const {
        palette: { type, text },
    } = useTheme()
    const confirm = useConfirm()
    const setFlags = useFlags()[1]

    return (
        <Box
            bgcolor={type === 'dark' ? '#000' : undefined}
            height={window.innerHeight}
            pt={2}
            pb={8}
        >
            <Box display='flex' flexDirection='column' height='100%'>
                {noButtons && (
                    <Box display='flex' height={72} pr={5}>
                        <Box
                            display='flex'
                            alignItems='flex-start'
                            pl={5}
                            style={{ gap: 8 }}
                        >
                            {progress && (
                                <Box
                                    alignItems='center'
                                    color={text.secondary}
                                    display='flex'
                                    onClick={() =>
                                        setFlags((flags) => ({
                                            ...flags,
                                            darkMode: !flags.darkMode,
                                        }))
                                    }
                                    style={{ marginTop: 6 }}
                                >
                                    <CircleSlice3 style={{ marginRight: 8 }} />
                                    <Typography variant='h6'>
                                        {progress}%
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Box flex={1} />
                        {chat && (
                            <Box>
                                <Button
                                    color='primary'
                                    endIcon={<LiveHelp />}
                                    variant='outlined'
                                >
                                    Carla
                                </Button>
                            </Box>
                        )}
                    </Box>
                )}
                {!noButtons && (
                    <Box
                        // justifyContent='flex-end'
                        display='flex'
                        pb={3}
                        pl={5}
                        pt={2}
                        style={{ gap: 8 }}
                    >
                        <Button
                            color='primary'
                            onClick={() => {
                                send('BACK')
                            }}
                            style={{ color: 'white' }}
                            variant='contained'
                        >
                            <ArrowBack />
                        </Button>
                        {!noReset && (
                            <Button
                                color='primary'
                                onClick={() => {
                                    confirm({
                                        description:
                                            'Are you sure you want to lose all progress?',
                                        title: 'Please Confirm',
                                    }).then(() => {
                                        send('RESET')
                                    })
                                }}
                                style={{ color: 'white' }}
                                variant='contained'
                            >
                                <Clear />
                            </Button>
                        )}
                        {chat && (
                            <Box
                                display='flex'
                                justifyContent='flex-end'
                                flex={1}
                                pr={5}
                            >
                                <Button
                                    color='primary'
                                    endIcon={<LiveHelp />}
                                    variant='outlined'
                                >
                                    Carla
                                </Button>
                            </Box>
                        )}
                    </Box>
                )}
                <Box display='flex' flexDirection='column' height='100%' px={5}>
                    {children}
                </Box>
            </Box>
        </Box>
    )
}
