import { Box, Button, Typography, useTheme } from '@material-ui/core'
import { Layout, LayoutProps } from 'components/molecules/Layout'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface WelcomeProps {
    layout: LayoutProps
}

export const Welcome: React.FC<WelcomeProps> = ({ layout }) => {
    const theme = useTheme()
    const color = theme.palette.primary.main
    return (
        <Layout {...layout} noButtons>
            <Box pt={2}>
                <Typography
                    align='center'
                    style={{ color, fontFamily: 'Nunito' }}
                    variant='h1'
                >
                    PEASY
                </Typography>
                <Typography align='center'>PROPERTY MADE EASY</Typography>
            </Box>
            <Box flex={1} />
            <Box pb={3}>
                <Typography align='center'>I AM A</Typography>
            </Box>
            <Box display='flex' flexDirection='column' style={{ gap: 24 }}>
                <Button
                    color='primary'
                    onClick={() => layout.send('BUYER')}
                    size='large'
                    style={{ color: 'white' }}
                    variant='contained'
                >
                    Buyer
                </Button>
                <Button
                    color='primary'
                    onClick={() => layout.send('RENTER')}
                    size='large'
                    style={{ color: 'white' }}
                    variant='contained'
                >
                    Renter
                </Button>
                <Button
                    color='primary'
                    onClick={() => layout.send('SELLER')}
                    size='large'
                    style={{ color: 'white' }}
                    variant='contained'
                >
                    Seller
                </Button>
                <Button
                    color='primary'
                    onClick={() => layout.send('DEVELOPER')}
                    size='large'
                    style={{ color: 'white' }}
                    variant='contained'
                >
                    Developer
                </Button>
                <Button
                    color='primary'
                    onClick={() => layout.send('SUMMARY')}
                    size='large'
                    variant='outlined'
                >
                    Log in
                </Button>
            </Box>
        </Layout>
    )
}
