import { Box, Step, StepLabel, Stepper } from '@material-ui/core'
import { config } from 'config'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ApplicationStepperProps {
    activeStep: number
    tab: string
}

export const ApplicationStepper: React.FC<ApplicationStepperProps> = ({
    activeStep,
    tab,
}) => {
    return (
        <Box mx={-5}>
            {tab !== 'summary' && config[tab].subcategories && (
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    style={{ backgroundColor: 'transparent' }}
                >
                    {Object.keys(config[tab].subcategories || []).map(
                        (subcategory, idx) => (
                            <Step key={subcategory}>
                                <StepLabel>
                                    {
                                        config[tab].subcategories?.[subcategory]
                                            .label
                                    }
                                </StepLabel>
                            </Step>
                        )
                    )}
                </Stepper>
            )}
        </Box>
    )
}
