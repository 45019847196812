import { Box } from '@material-ui/core'
import { Action, ActionButtons } from 'components/molecules/ActionButtons'
import { Header, HeaderProps } from 'components/molecules/Header'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StepCommonProps {
    actions?: Action[]
    header: HeaderProps
    onNext?: () => void
}

export const StepCommon: React.FC<StepCommonProps> = ({
    actions,
    children,
    header,
    onNext,
}) => {
    return (
        <Box justifyContent='center' display='flex' height='100%' width='100%'>
            <Box
                display='flex'
                flexDirection='column'
                height='100%'
                width={['100%', '100%', '100%', 800]}
            >
                <Header {...header} />
                <Box
                    display='flex'
                    flexDirection='column'
                    flex={1}
                    height='100%'
                >
                    {children}
                </Box>
                <ActionButtons
                    actions={
                        actions || [{ label: 'Continue', onClick: onNext }]
                    }
                />
            </Box>
        </Box>
    )
}
