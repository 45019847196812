import { Box, Fab } from '@material-ui/core'
import { Fields } from 'components/molecules/Fields'
import { Header, HeaderProps } from 'components/molecules/Header'
import { LayoutProps } from 'components/molecules/Layout'
import { Field } from 'config'
import { Pencil } from 'mdi-material-ui'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CategoryProps {
    fields: Field[]
    layout: LayoutProps
    header: HeaderProps
}

export const Category: React.FC<CategoryProps> = ({
    fields,
    layout,
    header,
}) => {
    return (
        <>
            <Header {...{ ...header, variant: 'small' }} />
            <Box display='flex' flexDirection='column'>
                <Fields fields={fields} />
            </Box>
            <Box position='absolute' bottom={96} right={24}>
                <Fab
                    color='primary'
                    onClick={() => layout.send('EDIT')}
                    style={{ color: 'white' }}
                >
                    <Pencil />
                </Fab>
            </Box>
        </>
    )
}
