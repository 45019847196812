import { Box } from '@material-ui/core'
import { Layout, LayoutProps } from 'components/molecules/Layout'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MiscProps {
    layout: LayoutProps
}

export const Misc: React.FC<MiscProps> = ({ children, layout }) => {
    return (
        <Layout {...layout}>
            {children}
            <Box flex={1} />
        </Layout>
    )
}
