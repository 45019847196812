import {
    Box,
    Card as MuiCard,
    CardContent,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { CircleOutline, CircleSlice3 } from 'mdi-material-ui'
import { ReactNode } from 'react'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardProps {
    icon?: ReactNode
    onClick: () => void
    subheader?: string
    status: number
    title: string
}

const useStyles = makeStyles(({ palette }) => ({
    card: {
        background: palette.primary.main,
        color: palette.common.white,
        flex: 1,
        minHeight: 100,
    },
    cardContent: {
        '&:last-child': {
            paddingBottom: 16,
        },
    },
}))

const statuses: {
    [key: number]: {
        icon: ReactNode
        label: string
    }
} = {
    10: { icon: <CircleOutline />, label: 'New' },
    20: { icon: <CircleSlice3 />, label: 'In Progress' },
    30: { icon: <CheckCircle />, label: 'Done' },
}

export const Card: React.FC<CardProps> = ({
    icon,
    onClick,
    subheader,
    status,
    title,
}) => {
    const classes = useStyles()
    return (
        <MuiCard
            className={classes.card}
            color='primary'
            elevation={0}
            onClick={onClick}
            style={{ height: '100%' }}
        >
            <CardContent
                className={classes.cardContent}
                style={{ height: '100%' }}
            >
                <Box display='flex' flexDirection='column' height='100%'>
                    <Box
                        alignItems='center'
                        justifyContent='flex-end'
                        display='flex'
                    >
                        <Typography
                            style={{ color: '#f2f2f2', marginRight: 8 }}
                            variant='overline'
                        >
                            {statuses[status].label}
                        </Typography>
                        {statuses[status].icon}
                    </Box>
                    <Box flex={1} />
                    <Box>
                        <Box alignItems='center' display='flex'>
                            <Box
                                alignItems='center'
                                display='flex'
                                mr={1.5}
                                style={{ marginTop: -2 }}
                            >
                                {icon}
                            </Box>
                            <Typography variant='h4'>{title}</Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant='subtitle2'
                                style={{ color: '#f2f2f2' }}
                            >
                                {subheader}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </MuiCard>
    )
}
