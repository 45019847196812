import { App } from 'components/organisms/App'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'

ReactDOM.render(
    <RecoilRoot>
        <App />
    </RecoilRoot>,
    document.getElementById('root')
)
