import { Box, Typography } from '@material-ui/core'
import { Action } from 'components/molecules/ActionButtons'
import { Documents } from 'components/molecules/Document'
import { Fields } from 'components/molecules/Fields'
import { HeaderProps } from 'components/molecules/Header'
import { Step } from 'components/organisms/Form'
import { theme } from 'config/theme'
import { Account, Bank, ClipboardListOutline } from 'mdi-material-ui'
import { ReactNode } from 'react'
import { ReactComponent as Folder } from './Folder.svg'
import { ReactComponent as House } from './House.svg'
import { ReactComponent as Loan } from './Loan.svg'
import { ReactComponent as Person } from './Person.svg'

interface Config {
    [key: string]: Category
}

// export interface Field extends Step {
export interface Field {
    name?: string
    label: string
    summary?: boolean
    value?: string
}

interface Category extends Subcategory {
    incomplete?: boolean
    icon: ReactNode
    subcategories?: { [key: string]: Subcategory }
}

interface Subcategory {
    fields?: Field[]
    label: string
}

export const journeys: { [key: string]: Step[] } = {
    buyer: [
        {
            header: {
                heading: 'Welcome to Peasy',
                subheading: 'Property Made Easy'
                
            },
            type: 'intro',
        },
        {
            actions: [{ label: 'First Time Buyer' }, { label: 'Re-Financing' }],
            header: {
                heading: 'How can we help?',
                subheading:
                    'Are you a first time buyer or looking to re-finance?',
            },
            type: 'radio',
        },
        {
            children: (
                <>
                    <Typography>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Nostrum quod assumenda praesentium sit corporis
                        perspiciatis, officiis ratione rem quisquam cum
                        dignissimos in eligendi laboriosam dolorem, labore ad
                        illo voluptas et?
                    </Typography>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Asperiores eum facilis illum, quis aperiam libero
                        sit ullam ea possimus. Laborum voluptates, repudiandae
                        laudantium ratione veritatis ab necessitatibus illum
                        provident in.
                    </Typography>
                </>
            ),
            header: {
                heading: 'Did you know?',
                subheading: 'Lorem ipsum dolor sit',
            },
            type: 'intro',
        },
        {
            actions: [{ label: 'Yes' }, { label: 'No' }],
            header: {
                heading: 'Tell us about your home?',
                subheading: 'Do you have a property on your mind',
            },
            type: 'radio',
        },
        {
            actions: [{ label: 'Yes' }, { label: 'No' }],
            header: {
                heading: 'Type of residence',
                subheading: 'Are you planning on living in the property?',
            },
            type: 'radio',
        },
        {
            header: {
                heading: 'Registration',
                subheading: 'TBC',
            },
            type: 'intro',
        },
    ],
    renter: [
        {
            header: {
                heading: 'Renter Journey',
                subheading: 'Coming Soon - Get matched with properties, get buyer ready ',
            },
            type: 'intro',
        },
    ],
    seller: [
        {
            header: {
                heading: 'Seller Journey',
                subheading: 'Coming Soon - Get matched with approved buyers looking to buy your home',
            },
            type: 'intro',
        },
    ],
    developer: [
        {
            header: {
                heading: 'Developer Journey',
                subheading: 'Coming Soon - meet approved home buyers looking to buy at your development',
            },
            type: 'intro',
        },
    ],
}

export const config: Config = {
    summary: {
        icon: <ClipboardListOutline height={20} width={20} />,
        label: 'Summary',
    },
    property: {
        fields: [
            {
                label: 'EirCode',
                value: 'D14 P440',
            },
            {
                label: 'Asking Price',
                value: '€400k',
            },
        ],
        icon: <House height={20} width={20} />,
        label: 'Property',
    },
    mortgage: {
        fields: [
            {
                label: 'Mortgage Amount',
                value: '€350k',
            },
            {
                label: 'Mortgage Term',
                value: '27 years',
            },
        ],
        incomplete: true,
        label: 'Mortgage',
        icon: <Bank height={20} width={20} />,
        subcategories: {
            mortgage: { label: 'Mortgage' },
            needs: { label: 'Needs' },
        },
    },
    you: {
        fields: [
            {
                label: 'Annual Salary',
                value: '€80k',
            },
            {
                label: 'Age',
                value: '40yo',
            },
        ],
        incomplete: true,
        label: 'You',
        icon: <Account height={20} width={20} />,
        subcategories: {
            personalInfo: {
                fields: [
                    {
                        label: 'First Name',
                        value: 'Tom',
                    },
                    {
                        label: 'Last Name',
                        value: 'Kostus',
                    },
                    {
                        label: 'Email Address',
                        value: 'tom@effco.net',
                    },
                    {
                        label: 'Phone Number',
                        value: '087 963 7998',
                    },
                    {
                        label: 'Annual Salary',
                        value: '€80k',
                    },
                    {
                        label: 'Age',
                        value: '40yo',
                    },
                ],
                label: 'Personal Info',
            },
            incomeExpenses: {
                label: 'Income & Expenses',
            },
            assetsSavingsLoans: {
                label: 'Assets, Savings & Loans',
            },
            taxCredit: {
                label: 'Taxes & Credits',
            },
        },
    },
    documents: {
        incomplete: true,
        label: 'Documents',
        icon: <Folder height={20} width={20} />,
        subcategories: {
            personal: { label: 'Personal' },
            employment: { label: 'Employment' },
        },
    },
}

export const summaryConfig: {
    [key: string]: {
        icon: ReactNode
        label: string
        content: ReactNode
        status: number
        subheader: string
    }
} = {
    property: {
        status: 30,
        content: (
            <Box display='flex' flexDirection='column' width='100%'>
                <Fields fields={config.property.fields || []} />
            </Box>
        ),
        icon: (
            <House
                color={theme().palette.primary.main}
                height={20}
                width={20}
            />
        ),
        label: 'Property',
        subheader: 'D14 P440 | 3BED HOUSE | €400k',
    },
    mortgage: {
        subheader: 'EBS | €350k | 27 years',
        status: 30,
        content: (
            <Box display='flex' flexDirection='column' width='100%'>
                <Fields fields={config.mortgage.fields || []} />
            </Box>
        ),
        icon: (
            <Loan color={theme().palette.primary.main} height={20} width={20} />
        ),
        label: 'Mortgage',
    },
    you: {
        subheader: 'SINGLE | €80k | 40 yo',
        status: 20,
        content: (
            <Box display='flex' flexDirection='column' width='100%'>
                <Fields fields={config.you.fields || []} />
            </Box>
        ),
        icon: (
            <Person
                color={theme().palette.primary.main}
                height={20}
                width={20}
            />
        ),
        label: 'You',
    },
    documents: {
        status: 10,
        content: (
            <Box display='flex' style={{ gap: 16 }} width='100%'>
                <Documents
                    documents={[
                        { label: 'Proof of Address', type: 'pdf' },
                        { label: 'Photo ID', type: 'image' },
                        { label: 'Bank Statements', type: 'pdf' },
                        { label: 'Payslips', type: 'image' },
                    ]}
                />
            </Box>
        ),
        label: 'Documents',
        subheader: '4/6 Uploaded',
        icon: (
            <Folder
                color={theme().palette.primary.main}
                height={20}
                width={20}
            />
        ),
    },
}

export const questions: {
    you: {
        personalInfo: {
            actions?: Action[]
            header: HeaderProps
            type: Step['type']
        }[]
    }
    mortgage: {
        actions?: Action[]
        header: HeaderProps
        type: Step['type']
    }[]
} = {
    mortgage: [
        {
            type: 'intro',
            header: {
                heading: 'Introduction',
                subheading:
                    'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt molestias error cumque, repellendus magnam dolores ea, aliquid blanditiis earum fuga numquam nisi ab assumenda fugiat corrupti ratione et fugit! Dolor?',
            },
        },
        {
            actions: [
                {
                    label: 'Yes',
                },
                {
                    label: 'No',
                },
            ],
            type: 'radio',
            header: {
                heading: 'Have you found a property?',
                subheading:
                    'Do you currently have a specific property in mind?',
            },
        },
        {
            type: 'currency',
            header: {
                heading: 'Value',
                subheading:
                    'What is the estimated value of the property you are looking to buy?',
            },
        },
    ],
    you: {
        personalInfo: [
            {
                type: 'intro',
                header: {
                    heading: 'Welcome to your form',
                    subheading:
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt molestias error cumque, repellendus magnam dolores ea, aliquid blanditiis earum fuga numquam nisi ab assumenda fugiat corrupti ratione et fugit! Dolor?',
                },
            },
            {
                actions: [
                    {
                        label: 'Single',
                    },
                    {
                        label: 'Married',
                    },
                    {
                        label: 'Separated',
                    },
                    {
                        label: 'Divorced',
                    },
                    {
                        label: 'Widowed',
                    },
                ],
                type: 'radio',
                header: { heading: 'What is your marital status?' },
            },
            {
                type: 'radio',
                header: { heading: 'What is your date of birth?' },
            },
        ],
    },
}
