import { createTheme, responsiveFontSizes } from '@material-ui/core'

export const theme = (type: 'dark' | 'light' = 'light') =>
    responsiveFontSizes(
        createTheme({
            overrides: {
                MuiButton: {
                    label: {
                        letterSpacing: '0.2em',
                        fontSize: 18,
                        fontWeight: 900,
                    },
                },
                MuiAccordion: {
                    root: {
                        '&:before': {
                            display: 'none',
                        },
                    },
                },
            },
            props: {
                MuiButton: { disableElevation: true },
            },
            palette: {
                type,
                primary: { main: '#45bdcd' },
                // background: { paper: '#000' },
            },
            shape: { borderRadius: 8 },
            typography: {
                fontFamily: 'Nunito',
                h1: {
                    fontFamily: 'Montserrat',
                    fontWeight: 900,
                },
                h2: { fontFamily: 'Montserrat', fontWeight: 900 },
                h3: { fontFamily: 'Montserrat', fontWeight: 900 },
                h4: { fontFamily: 'Montserrat', fontWeight: 900 },
                h5: { fontFamily: 'Montserrat', fontWeight: 900 },
                h6: { fontFamily: 'Montserrat', fontWeight: 900 },
            },
        })
    )
