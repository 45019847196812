import { useTheme } from '@material-ui/core'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DigitalCustomerServiceIllustrationProps {
    height?: string
    primary?: string
    secondary?: string
}

export const DigitalCustomerServiceIllustration: React.FC<DigitalCustomerServiceIllustrationProps> =
    ({ height = '100%', primary, secondary }) => {
        const theme = useTheme()
        const primaryColor = primary || theme.palette.primary.main
        const secondaryColor = secondary || theme.palette.text.primary

        return (
            <svg
                viewBox='0 0 400 400'
                height={height}
                width='100%'
                xmlns='http://www.w3.org/2000/svg'
            >
                <g transform='matrix(2,0,0,2,0,0)'>
                    <path
                        d='M194.084,179.967c-62.557-.044-124.617,0-188.46,0a.589.589,0,1,0,.05,1.171c63.523.71,125.91.242,188.467.292a.746.746,0,1,0-.057-1.463Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M84.083,54.334c-.891-.969-1.228-.071-1.292.824-.433,5.916,6.044,8.224,8.231,3.253.54-1.229.483-1.641-.71-1.563A6.544,6.544,0,0,1,84.083,54.334Zm5.945,3.651c-.909,2.557-5.511,3.5-6.3-2.593A7.422,7.422,0,0,0,90.028,57.985Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M85.6,50.115c.71-.158.469-1.768.043-2.13C84.524,46.983,84.416,50.381,85.6,50.115Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M85.44,43.453a3.459,3.459,0,0,0-2.983,2.366c0,.106.142,1.065.987.12a2.839,2.839,0,0,1,2.131-1.129C86.8,44.561,86.406,43.432,85.44,43.453Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M91.434,54.007c.83-.266,1.343-.852.5-1.6a5.874,5.874,0,0,1-1.768-3.111.379.379,0,0,0-.753.086,5.85,5.85,0,0,0,1.8,3.65c-.895.54-1.513.249-1.854.547C88.785,54.085,89.67,54.574,91.434,54.007Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M95,44.789a2.344,2.344,0,0,0-2.131-1.421c-1.08,0-.866,1.051-.17,1.137a2.492,2.492,0,0,1,1.676.71C94.815,45.584,95.163,45.414,95,44.789Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M93.2,50.023c.71-.058.6-2.372-.171-2.23C92.109,47.971,92.514,50.079,93.2,50.023Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M74.091,71.962c2.356,3.213,10.284,8.963,14.758,11.442,1.186.66,1.847,1.235,2.578-.32,1.307-2.749,1.882-11.314,1.215-12.457-.206-.348-.71-.185-.845.106-.3.54.1,7.713-1.378,12.166A73.79,73.79,0,0,1,74.858,71.3C74.481,70.932,73.715,71.45,74.091,71.962Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M161.555,69.952c-.42.2-.206.937.263.8,4.68-1.363,5.824-4.4,8.381-5.681,3.246-1.6,5.838-2.436,8.281-5.4.27-.32-.227-.753-.533-.49-3.551,3.054-4.595,3.21-8.445,4.971C166.974,65.3,166.686,67.548,161.555,69.952Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M159.446,62.2c1.04-.621,2.351-.93,3.508-1.484a24.685,24.685,0,0,1,5.753,2.372c.54.313,1.023-.206.711-.54,2.429-1.555,6.612-3.9,7.655-6.718.114-.313-.4-.753-.589-.469-1.285,1.875-4.566,4.2-7.813,6.506l-1.513-.711c1.826-1.086,6.18-5.724,6.912-7.869a.4.4,0,0,0-.654-.405c-2.053,1.9-3.651,5.32-7.437,7.813a17.828,17.828,0,0,0-1.845-.6A18.113,18.113,0,0,0,169.7,53.7a.4.4,0,0,0-.253-.5c-.891-.3-3.321,4.892-7.269,6.753a15.277,15.277,0,0,0-3.11,1.563C158.671,61.784,159.03,62.452,159.446,62.2Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M153.388,66.82c1.126-4.37,2.841-4.354,4.048-6.52a9.652,9.652,0,0,0,.895-5.682c-.114-.461-.71-.319-.767.093-.249,2.166.128,3.984-2.131,6.264-1.577,1.6-2.9,3.38-2.841,5.746A.405.405,0,0,0,153.388,66.82Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M102.727,156.039c-.976,5.241-1.911,12.905-.632,18.082.149.6,1.186.519,1.065-.071-1.086-5.412,0-13.167.945-17.947C104.325,154.981,102.989,154.633,102.727,156.039Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M92.961,68.418c11.875.361,23.914.6,35.427.71,6.59.078,10.809.6,12.287,10.554.113.76,1.42.668,1.349-.106-1.087-10.015-5.59-11.762-13.665-11.84-11.641-.113-23.537-.383-35.291-.341C92.4,67.431,92.287,68.4,92.961,68.418Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M32.776,142.14c1.054,8.388,3.878,11.229,12.855,11.463,31.378.76,65.988.333,97.728,0,10.043-.1,11.2-4.034,9.787-12.955-1.74-11.043-3.182-22.522-5.455-33.431-.12-.553-.966-.5-.9.086,1.179,11.293,3.417,22.408,5.022,33.623,1.108,7.74,1,11.2-8.345,11.228-29.979.086-67.011.789-96.215.269-5.732-.1-12.131-.049-13.2-10.368-2.167-21.009-4.077-42.074-6.13-63.1-.781-8.033,3.963-10.376,11.982-10.533,10.476-.213,21.584-.064,31.754,0a.625.625,0,1,0,.063-1.248c-.1-.006-23.823-.218-31.966-.044-7.976.149-14.12,2.258-13.168,11.953C28.614,99.924,30.172,121.41,32.776,142.14Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M77.741,155.236c-1.62,4.308-3.949,11.627-4.07,16.165-.135,5.369,1.939,7.152,7.053,7.152,11.818,0,25.639,0,37.642-.057.959,0,.8-1.477-.156-1.477-12.855,0-26.208.057-38.978,0-3.458,0-4.119-2.528-4.091-5.476.05-5.106,2.571-12.379,3.864-15.838A.674.674,0,0,0,77.741,155.236Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M35.447,136.323c36.676.06,76.6,0,113.047-.107.916,0,.831-1.42-.078-1.42-36.477,0-76.435.163-113.033.412C34.651,135.2,34.744,136.322,35.447,136.323Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M88.139,68.6c.227.661.987,3.31,1.676,3.076s-.341-2.841-.6-3.551A.6.6,0,0,0,88.139,68.6Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M73.1,41.862a4.261,4.261,0,0,1,4.126,2.749,9.323,9.323,0,0,0,4.035-6.023c3.728.05,15.34.71,15.8-.234s0-11.584-12.074-11.762C76.065,26.472,71.526,32.956,73.1,41.862Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M58.835,60.719c-1.347,1.706,1.7,1.172,3.949-.98s4.567-9.311,3.416-15.746.71-9.5,2.99-9.4c.27-2.067,1.8-8.168,6.868-10.54-3.544-4.922-11.619-7.344-17.806,1.094C53,32.317,59.545,42.573,60.262,48.894S60.179,59.016,58.835,60.719Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M76.179,69.561c.056-1.356-.15-7.585-.5-8.522a.554.554,0,0,0-1.008.092,69.556,69.556,0,0,0,.71,8.523A.413.413,0,0,0,76.179,69.561Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M95.951,40.236c0,5.589,0,16.094-4.062,21.307-.3.376.255,1.136,1.143.128,3.487-3.956,4.908-17,3.9-21.485C96.825,39.632,95.951,39.661,95.951,40.236Z'
                        fill={secondaryColor}
                    ></path>
                    <path
                        d='M106.931,61.621c4.887.038,12.074-3.551,15.626-6.825,17.968,8.381,41.733-1.882,34.338-22.728-6.291-17.755-37.84-17.315-45.951-3.409-3.984,6.826-3.551,17,4.006,21.25-1.307,3.445-4.808,8.665-8.473,10.924C106.271,60.939,105.9,61.613,106.931,61.621Zm8.637-10.043c.885-1.677,1.271-1.882-.419-3.061-6.343-4.4-6.485-12.94-2.955-19.176,6.8-12.017,34.8-12.855,41.975.781,8.9,16.932-8.3,32.877-31.016,23.367a.952.952,0,0,0-1.229.2c-4.446,4.14-7.606,5.4-12.848,6.591A34.861,34.861,0,0,0,115.568,51.578Z'
                        fill={primaryColor}
                    ></path>
                    <path
                        d='M143.8,41.365c-5.114,0-14.489.327-19.333.341-1.74,0-2.081,1.648,0,1.6,4.346-.085,17.046-.05,19.638-.4C144.957,42.8,144.666,41.365,143.8,41.365Z'
                        fill={primaryColor}
                    ></path>
                    <path
                        d='M122.833,36.01c2.784.212,21,.263,22.728-.923a.611.611,0,0,0-.071-1.044c-.789-.419-22.131.447-22.728.561C121.683,34.81,121.747,35.927,122.833,36.01Z'
                        fill={primaryColor}
                    ></path>
                    <path
                        d='M73.146,55.357a14.609,14.609,0,0,0,10.731,9.069c.135,1.421,1.2,1.982,2.358,2.33,6.563,1.939,8.445-4.546,2.7-4.9-1.42-.085-3.636-.049-4.623,1.151a13.428,13.428,0,0,1-9.943-8.4c2.926-1.889,3.238-12.131-2.344-11.47-.71-4.262-.639-8.637,1.015-11.968,4.134-8.338,19.731-7.983,23.857.086,2.422,4.737,1.712,16.974.71,22.173-.2,1.051,1.506.376,1.8.192,2.756-1.719,3.914-8.523.5-9.624.15-3.643.057-9.119-1.271-12.45-3.97-9.993-21.747-10.874-26.818-1.137-2.067,3.964-2.131,8.843-1.094,13.126C65.511,46.124,68.3,56.628,73.146,55.357Z'
                        fill={primaryColor}
                    ></path>
                    <path
                        d='M151.591,67.907c-1.712,2.13-13.629,19.744-18.572,24.943a29.42,29.42,0,0,1,2,2.209.387.387,0,0,1-.54.554c-6.661-6.456-17.756-16.229-25.227-20.81-3.225-1.974-7.465-3.473-14.915-4.553.348,3.772,2.841,23.189,2.94,35.64.22,8.984.71,18.189.81,27.145,6.584,0,12.543,0,17.045.042,1.421-19.034,3.552-33.707-2.7-42.613-.306-.441.511-.91.809-.469,4.361,6.257,4.972,13.011,4.617,20.433,4.368,2.749,15.284,5.931,19.829,4.858,4.93-1.165,21.875-33.381,24.382-42.436C159.744,70.314,153.92,68.141,151.591,67.907ZM103.117,91.884c2,1.783,4.106,3.459,6.009,5.348a.661.661,0,0,1,.057.661c-2.692,7.1-7.166,14.524-8.921,21.676-.191.8-4.36,3.508,7.9-21.676-1.747-1.6-3.517-3.161-5.242-4.773-1.9-1.768-2.1-.308,5.411-9.539-1.9-1.626-6.767-5.681-8.153-7.166-.355-.4,0-.781.447-.5,1.741,1.136,6.982,5.44,8.907,7.1a.625.625,0,0,1,.135.838C109.766,83.851,109.874,83.614,103.117,91.884Z'
                        fill={primaryColor}
                    ></path>
                    <path
                        d='M71.214,70.428c-1.8,0-16.236,6.079-20.54,15.049-3.551,7.358-5.731,35.683-6.079,47.671H62.9c-.454-6.392.909-19.645,2.131-26.179.1-.511.945-.362.9.142q-.711,7.812-1.421,15.625a99.805,99.805,0,0,0,3.552,10.384c9.3,0,19.666-.057,28.9-.057-.277-10.547-.384-18.509-1.016-27.777C89.985,97.928,73.757,76.152,71.214,70.428Zm21.257,40.483A215.785,215.785,0,0,0,72.052,97.757a.631.631,0,0,1-.3-.831c1.221-2.038,2.45-4.062,3.679-6.093A57.8,57.8,0,0,1,63.551,86.11a.632.632,0,0,1-.348-.81c1.555-3.047,3.132-5.987,4.723-9.013a.44.44,0,0,1,.8.362c-1.335,2.884-2.741,5.732-4.112,8.6A65.071,65.071,0,0,0,76.271,89.8c.327.1.675.71.511,1.008-1.172,2.081-2.407,4.12-3.615,6.186,6.144,3.453,14.581,8.6,19.887,13.36.539.518.056,1.015-.583.582Z'
                        fill={primaryColor}
                    ></path>
                </g>
            </svg>
        )
    }
