import { HeaderProps } from 'components/molecules/Header'
import { StepCommon } from '../StepCommon'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StepIntroProps {
    header: HeaderProps
    onNext: () => void
}

export const StepIntro: React.FC<StepIntroProps> = (props) => {
    return <StepCommon {...props} />
}
