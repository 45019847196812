import { Box, Typography, useTheme } from '@material-ui/core'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HeaderProps {
    heading: string
    subheading?: string
    variant?: 'large' | 'small'
}

export const Header: React.FC<HeaderProps> = ({
    heading,
    subheading,
    variant = 'large',
}) => {
    const theme = useTheme()
    const color = theme.palette.primary.main
    return (
        <Box pb={variant === 'large' ? 4 : 2} pt={2}>
            <Typography
                style={{ color }}
                variant={variant === 'large' ? 'h2' : 'h5'}
            >
                {heading}
            </Typography>
            {subheading && (
                <Box pt={3}>
                    <Typography variant='subtitle1'>{subheading}</Typography>
                </Box>
            )}
        </Box>
    )
}
