import { atom } from 'recoil'

interface Flags {
    darkMode: boolean
    tiles: boolean
}

export const flagsState = atom<Flags>({
    key: 'flags',
    default: { darkMode: false, tiles: false },
})
