import { Box, Button, AppBar as MuiAppBar, Typography } from '@material-ui/core'
import React from 'react'
import { ActionIcon } from '../ActionButtons'

export interface AppBarProps {
    title?: string
    actions?: ActionIcon[]
}

export const AppBar: React.FC<AppBarProps> = ({ actions = [], title }) => {
    return (
        <Box pb={5}>
            <MuiAppBar elevation={0} style={{ background: 'transparent' }}>
                <Box
                    alignItems='flex-end'
                    display='flex'
                    height={56}
                    px={3}
                    width='100%'
                >
                    {title && (
                        <Typography color='primary' variant='h4'>
                            {title}
                        </Typography>
                    )}
                    <Box flex={1} />
                    <Box display='flex' style={{ gap: 8 }}>
                        {actions.map((action, idx) => (
                            <Button
                                color='primary'
                                key={idx}
                                onClick={action.onClick}
                                size='small'
                                style={{ color: 'white' }}
                                variant='contained'
                                {...action.buttonProps}
                            >
                                {action.icon}
                            </Button>
                        ))}
                    </Box>
                </Box>
            </MuiAppBar>
        </Box>
    )
}
