import { Box, CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { Router } from 'components/organisms/Router'
import { useFlags } from 'config/flags'
import { theme } from 'config/theme'
import { ConfirmProvider } from 'material-ui-confirm'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppProps {}

export const App: React.FC<AppProps> = () => {
    const [flags] = useFlags()
    return (
        <MuiThemeProvider theme={theme(flags.darkMode ? 'dark' : 'light')}>
            <ConfirmProvider>
                <CssBaseline />
                <Box height='100vh'>
                    <Router />
                </Box>
            </ConfirmProvider>
        </MuiThemeProvider>
    )
}
