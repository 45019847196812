import {
    Box,
    IconButton,
    makeStyles,
    Slider,
    Typography,
} from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import { HeaderProps } from 'components/molecules/Header'
import {
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronRight,
} from 'mdi-material-ui'
import numeral from 'numeral'
import { useState } from 'react'
import AnimatedNumber from 'react-animated-number'
import { StepCommon } from '../StepCommon'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StepCurrencyProps {
    header: HeaderProps
    onNext: () => void
}

const useStyles = makeStyles({
    thumb: {
        marginTop: -11,
        marginLeft: -11,
        height: 24,
        width: 24,
    },
})

export const StepCurrency: React.FC<StepCurrencyProps> = (props) => {
    const classes = useStyles()
    const [value, setValue] = useState(0)
    const richMode = false
    const min = richMode ? 1000 : 0
    const max = richMode ? 100000 : 1000
    const smallDelta = richMode ? 100 : 1
    const largeDelta = richMode ? 1000 : 10
    const step = richMode ? 5000 : 50
    return (
        <StepCommon {...props}>
            <Box
                alignItems='center'
                display='flex'
                justifyContent='center'
                flex={1}
            >
                {value ? (
                    <Typography
                        color='primary'
                        style={{ fontSize: 96 }}
                        variant='h1'
                    >
                        <AnimatedNumber
                            component='text'
                            value={value}
                            // frameStyle={perc => (
                            //     perc === 100 ? {} : {backgroundColor: '#ffeb3b'}
                            // )}
                            duration={250}
                            formatValue={(n: number) =>
                                `${numeral(n * 1000).format(
                                    richMode ? '0,0.0a' : '0,0a'
                                )}`
                            }
                        />
                    </Typography>
                ) : null}
            </Box>
            <Box
                alignItems='center'
                display='flex'
                flexDirection='column'
                mb={4}
                style={{ gap: 8 }}
            >
                <Box display='flex' width='100%'>
                    <IconButton
                        color='primary'
                        disabled={value === 0}
                        onClick={() =>
                            setValue((value) => Math.max(0, value - largeDelta))
                        }
                    >
                        <ChevronDoubleLeft />
                    </IconButton>
                    <IconButton
                        color='primary'
                        disabled={value === 0}
                        onClick={() =>
                            setValue((value) => Math.max(0, value - smallDelta))
                        }
                    >
                        <ChevronLeft />
                    </IconButton>
                    <Box flex={1} />
                    <IconButton
                        color='primary'
                        disabled={value === max}
                        onClick={() =>
                            setValue((value) =>
                                Math.min(max, value + smallDelta)
                            )
                        }
                    >
                        <ChevronRight />
                    </IconButton>
                    <IconButton
                        color='primary'
                        disabled={value === max}
                        onClick={() =>
                            setValue((value) =>
                                Math.min(max, value + largeDelta)
                            )
                        }
                    >
                        <ChevronDoubleRight />
                    </IconButton>
                </Box>
                <Box px={2} width='100%'>
                    <Slider
                        classes={classes}
                        min={min}
                        max={max}
                        onChange={(_, value) =>
                            setValue(value as unknown as number)
                        }
                        step={step}
                        value={value}
                    />
                </Box>
            </Box>
        </StepCommon>
    )
}
