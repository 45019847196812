import { useMachine } from '@xstate/react'
import { createMachine } from 'xstate'
import { form } from './form'

const peasyStateMachine = createMachine({
    id: 'peasy',
    initial: 'new',
    states: {
        new: {
            initial: 'intro',
            states: {
                intro: {
                    on: {
                        BUYER: '#peasy.buyer',
                        SELLER: '#peasy.seller',
                        RENTER: '#peasy.renter',
                        DEVELOPER: '#peasy.developer',
                        SUMMARY: '#peasy.creditJourney.summary',
                    },
                },
            },
        },
        buyer: {
            initial: 'intro',
            states: {
                intro: {
                    on: {
                        BACK: '#peasy.new.intro',
                        NEXT: '#peasy.accountManager',
                        RESET: '#peasy.new.intro',
                    },
                },
            },
        },
        accountManager: {
            initial: 'awaiting',
            states: {
                awaiting: {
                    after: { 5000: 'assigned' },
                },
                assigned: {
                    on: {
                        START: '#peasy.creditJourney',
                    },
                },
            },
        },
        creditJourney: {
            initial: 'start',
            states: {
                start: {
                    on: {
                        SUMMARY: 'summary',
                    },
                },
                summary: {
                    on: {
                        PROPERTY: 'property',
                        MORTGAGE: 'mortgage',
                        YOU: 'you',
                        DOCUMENTS: 'documents',
                    },
                },
                property: form,
                mortgage: form,
                you: form,
                documents: form,
            },
        },
        renter: {
            initial: 'intro',
            states: {
                intro: {
                    on: {
                        BACK: '#peasy.new.intro',
                        RESET: '#peasy.new.intro',
                    },
                },
            },
        },
        seller: {
            initial: 'intro',
            states: {
                intro: {
                    on: {
                        BACK: '#peasy.new.intro',
                        RESET: '#peasy.new.intro',
                    },
                },
            },
        },
        developer: {
            initial: 'intro',
            states: {
                intro: {
                    on: {
                        BACK: '#peasy.new.intro',
                        RESET: '#peasy.new.intro',
                    },
                },
            },
        },
    },
})

const useStateMachine = () => useMachine(peasyStateMachine)

export default useStateMachine
