import { Box } from '@material-ui/core'
import { Header, HeaderProps } from 'components/molecules/Header'
import { Layout, LayoutProps } from 'components/molecules/Layout'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TextWithImageProps {
    header: HeaderProps
    layout: LayoutProps
}

export const TextWithImage: React.FC<TextWithImageProps> = ({
    children,
    header,
    layout,
}) => {
    return (
        <Layout {...layout} send={layout.send}>
            <Header {...header} />
            <Box display='flex' flexDirection='column' height='100%'>
                {children}
            </Box>
        </Layout>
    )
}
