import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    Typography,
} from '@material-ui/core'
import { summaryConfig } from 'config'
import { ChevronDown } from 'mdi-material-ui'
import { useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SummaryProps {}

export const Summary: React.FC<SummaryProps> = () => {
    const initialState = Object.keys(summaryConfig).map(
        (summaryCategory) => false
    )
    const [expanded, setExpanded] = useState(initialState)

    const expand = (idx: number) => {
        setExpanded((expandedOld) => {
            const expandedNew = expandedOld.map((_) => false)
            expandedNew[idx] = !expandedOld[idx]
            return expandedNew
        })
    }
    return (
        <>
            <Box pt={2} pb={2}>
                <Typography color='primary' variant='h4'>
                    Your Application
                </Typography>
                <Box py={1}>
                    <Divider />
                </Box>
                <Typography color='textSecondary' variant='subtitle2'>
                    Your mortgage application at a glance. Click below to see
                    more details
                </Typography>
            </Box>
            {Object.keys(summaryConfig).map((summaryCategory, idx) => (
                <Accordion
                    expanded={expanded[idx]}
                    onChange={() => expand(idx)}
                    key={summaryCategory}
                    style={{
                        backgroundColor: 'transparent',
                    }}
                    elevation={0}
                >
                    <AccordionSummary
                        expandIcon={<ChevronDown color='primary' />}
                        style={{
                            height: 80,
                            marginLeft: 0,
                            marginRight: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                        }}
                    >
                        <Box display='flex' alignItems='center' pr={2}>
                            {summaryConfig[summaryCategory].icon}
                        </Box>
                        <Typography
                            color='primary'
                            variant='h5'
                            style={{ flex: 1 }}
                        >
                            {summaryConfig[summaryCategory].label}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {summaryConfig[summaryCategory].content}
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    )
}
