import { Box } from '@material-ui/core'
import { Action } from 'components/molecules/ActionButtons'
import { HeaderProps } from 'components/molecules/Header'
import { StepCommon } from '../StepCommon'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StepRadioProps {
    actions: Action[]
    header: HeaderProps
}

export const StepRadio: React.FC<StepRadioProps> = (props) => {
    return (
        <StepCommon {...props}>
            <Box flex={1} />
        </StepCommon>
    )
}
