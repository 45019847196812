import {
    Badge,
    BottomNavigationAction,
    BottomNavigation as MuiBottomNavigation,
} from '@material-ui/core'
import { config } from 'config'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BottomNavigationProps {
    tab: string
    setTab: any
}

export const BottomNavigation: React.FC<BottomNavigationProps> = ({
    tab,
    setTab,
}) => {
    return (
        <MuiBottomNavigation
            value={tab}
            style={{
                backgroundColor: 'transparent',
                bottom: 8,
                left: 0,
                overflow: 'hidden',
                position: 'absolute',
                width: '100vw',
            }}
        >
            {Object.keys(config).map((category) => (
                <BottomNavigationAction
                    key={category}
                    label={config[category].label}
                    onClick={() => setTab(category)}
                    value={category}
                    icon={
                        config[category].incomplete ? (
                            <Badge color='primary' variant='dot'>
                                {config[category].icon}
                            </Badge>
                        ) : (
                            config[category].icon
                        )
                    }
                />
            ))}
        </MuiBottomNavigation>
    )
}
