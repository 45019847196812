export const form = {
    initial: 'view',
    states: {
        view: {
            on: {
                EDIT: 'edit',
            },
        },
        edit: {
            on: {
                SAVE: 'view',
                CANCEL: 'view',
            },
        },
    },
    on: {
        SUMMARY: 'summary',
        PROPERTY: 'property',
        MORTGAGE: 'mortgage',
        YOU: 'you',
        DOCUMENTS: 'documents',
    },
}
