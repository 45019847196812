import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
} from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { Header } from 'components/molecules/Header'
import { LayoutProps } from 'components/molecules/Layout'
import { Misc } from 'components/pages/Misc'
import { useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreditJourneyStartProps {
    layout: LayoutProps
}

export const CreditJourneyStart: React.FC<CreditJourneyStartProps> = ({
    layout,
}) => {
    const [consents, setConsents] = useState([false, false, false])

    const toggle = (idx: number) => {
        let newConsents = [...consents]
        newConsents[idx] = !consents[idx]
        setConsents(newConsents)
    }

    return (
        <Misc layout={{ noButtons: true, ...layout }}>
            <Box display='flex' flexDirection='column' height='100%'>
                <Header heading='Welcome to your Credit Journey' />
                <Typography>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptates, cupiditate iste. Aliquid veniam dolorum amet
                    error itaque, similique.
                </Typography>
                {/* <Box pt={4} pb={2}>
                        <Typography color='primary' variant='h6'>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit
                        </Typography>
                    </Box>
                    <Typography>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Voluptates, cupiditate iste. Aliquid veniam dolorum amet
                        error itaque, similique, modi tempore sint, vel nulla
                        quod non nisi quasi impedit qui pariatur?
                    </Typography> */}
                <Box pt={4} pb={2}>
                    <Typography color='primary' variant='h6'>
                        Consents
                    </Typography>
                </Box>
                <Box pb={2}>
                    <Typography>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </Typography>
                </Box>
                <Box pb={2}>
                    <FormControlLabel
                        checked={consents[0]}
                        control={<Checkbox color='primary' name='A' />}
                        label='Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Voluptates, cupiditate iste.'
                        onChange={() => toggle(0)}
                    />
                </Box>
                <Box pb={2}>
                    <FormControlLabel
                        checked={consents[1]}
                        control={<Checkbox color='primary' name='B' />}
                        label='Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Voluptates, cupiditate iste.'
                        onChange={() => toggle(1)}
                    />
                </Box>
                <Box pb={2}>
                    <FormControlLabel
                        checked={consents[2]}
                        control={<Checkbox color='primary' name='C' />}
                        label='Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Voluptates, cupiditate iste.'
                        onChange={() => toggle(2)}
                    />
                </Box>
                <Box flex={1} />
                <Button
                    color='primary'
                    disabled={consents.some((consent) => consent === false)}
                    endIcon={<ArrowForward />}
                    fullWidth
                    onClick={() => layout.send('SUMMARY')}
                    size='large'
                    style={{ color: 'white' }}
                    variant='contained'
                >
                    Application
                </Button>
            </Box>
        </Misc>
    )
}
